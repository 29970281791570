import styled from 'styled-components';
import { Color, MobileQuery, MobileSideMargin, PcInnerPadding, PcQuery, Text } from '../../styles';
import Funder from '../../services/funder';
import SmoothImage from '../atoms/SmoothImage';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1230px;

  ${MobileQuery} {
    padding: 0 ${MobileSideMargin}px 64px ${MobileSideMargin}px;
    gap: 32px;
  }

  ${PcQuery} {
    gap: 48px;
    margin: 0 32px 0 ${PcInnerPadding};
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 800;
  line-height: 24px;

  color: ${Color.WHITE};
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  ${MobileQuery} {
    gap: 6px;
  }

  ${PcQuery} {
    gap: 8px;
  }

  & > p {
    font-weight: 400;

    color: ${Color.WHITE}dd;

    ${MobileQuery} {
      font-size: 16px;
      line-height: 20px;
    }

    ${PcQuery} {
      font-size: 18px;
      line-height: 24px;
    }
  }

  & > p > u {
    text-decoration: underline;
    text-underline-offset: 4px;
  }

  ${MobileQuery} {
    margin: -20px 0 0 0;
  }

  ${PcQuery} {
    margin: -28px 0 0 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Graph = styled.div`
  flex-grow: 1;
  height: 24px;
  background: ${Color.GRAY};
  border-radius: 4px;
`;

const GraphIndicator = styled.div`
  height: 100%;
  background: ${Color.PRIMARY};
  border-radius: 4px;
`;

const Indexes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Index = styled.div`
  color: ${Color.WHITE};
  font-weight: 700;

  ${PcQuery} {
    font-size: 20px;
  }

  ${MobileQuery} {
    font-size: 16px;
  }
`;

const Date = styled.div`
  color: ${Color.GRAY};
  font-weight: 700;

  ${PcQuery} {
    font-size: 16px;
  }

  ${MobileQuery} {
    font-size: 12px;
  }
`;

const Link = styled.a`
  color: ${Color.PRIMARY};
  font-weight: 700;
  text-align: center;

  ${PcQuery} {
    font-size: 24px;
  }

  ${MobileQuery} {
    font-size: 18px;
  }
`;

const Image = styled(SmoothImage)`
  width: 100%;
  aspect-ratio: 1200 / 928;
  border-radius: 16px;
`;

const Line = styled.div`
  height: 2px;
  width: 100%;
  background: ${Color.DARK_GRAY};
`;

const OutboundLink = styled.a`
  font-weight: 600;
  text-decoration: underline;
`;

const Littly = styled.a`
  width: 150px;
  height: 42.11px;
  background: ${Color.PRIMARY};
  color: ${Color.WHITE};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Fund = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const SurveyTemplate: React.FC = () => {
  return (
    <Layout>
      <Title>IZFLIX 운영 안내</Title>
      <Description>
        <p>안녕하세요, IZFLIX 사용자 여러분.</p>
        <p>먼저, 저희 IZFLIX를 2년 넘게 사랑해주시고 이용해주신 모든 분들께 감사드립니다.</p>
        <p> 여러분의 성원 덕분에 여기까지 올 수 있었습니다. 정말 고맙습니다!</p>
      </Description>
      <Description>
        <p>그동안 IZFLIX 운영에는 여러 가지 어려움이 있었으며, 운영자도 여러 차례 변경되어 중단을 고려하기도 했던 시기가 있었습니다.</p>
        <p>이러한 과정 속에서 저희는 내부적으로 많은 고민과 논의를 거쳤고, 이제 모든 문제가 해결되어 원래의 운영팀이 복귀하게 되었습니다.</p>
        <p>저희는 새로운 출발을 준비하며, 더 나은 서비스를 제공하기 위해 최선을 다하고 있습니다.</p>
      </Description>
      <Description>
        <p>아이즈원은 이제 아이즈원을 넘어 훨씬 더 멋진 아티스트로 성장하였습니다.</p>
        <p>저희도 그 변화에 발맞추어, 아이즈원 12명을 지속적으로 응원하고 따라갈 수 있도록 다양한 콘텐츠와 서비스를 준비하고 있습니다.</p>
        <p>특히, 제작년에 올렸던 <OutboundLink href="https://youtu.be/QBS4f6TyeHQ" target="_blank">"IZGPT" 컨셉
          영상</OutboundLink>의 실제 구현도 진행하고 있으며, 올해 10월 29일 아이즈원 6주년을 목표로 개발하고 있습니다.</p>
        <p>많은 분들이 기대 아닌 기대를 하신 만큼, 저희는 이 프로젝트에 최선을 다하고 있으며, 곧 여러분께 좋은 소식을 전달할 수 있을 것으로 기대하고 있습니다.</p>
      </Description>
      <Description>
        <p>이와 관련하여, 여러분의 소중한 의견을 듣고자 합니다.</p>
        <p>어떤 서비스가 필요하신지, 그동안 무엇이 불편했는지에 대한 여러분의 생각을 공유해 주시면 매우 감사하겠습니다.</p>
        <p>아래의 링크를 통해 설문에 참여해 주시면 감사하겠습니다.</p>
        <p>앞으로도 많은 관심과 사랑 부탁드립니다. 감사합니다.</p>
      </Description>
      <Line/>
      <Link href={'https://forms.gle/xuvzmKJFY5jkSrcLA'} target={'_blank'}>
        설문링크
      </Link>
      <Line/>
      <Fund>
        <Littly href="https://litt.ly/msqr" target="_blank">8월모금</Littly>
        <a href="https://buymeacoffee.com/msqr" rel="noreferrer" target="_blank">
          <img
            alt="Buy Me A Coffee"
            src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
            style={{ width: '150px' }}
          />
        </a>
      </Fund>
      <Line/>
      <Description>
        <p>* 기존 IZFLIX 서비스는 계속 유지됩니다.</p>
      </Description>
    </Layout>
  );
};

export default SurveyTemplate;
