import { useTranslation } from 'react-i18next';
import Meta from '../components/Meta';
import Page from './Page';
import SurveyTemplate from '../components/templates/SurveyTemplate';

const SurveyPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <Meta
        data={{
          title: `IZFLIX 운영 안내`,
          url: 'https://izflix.net/profile/survey',
        }}
      />
      <SurveyTemplate />
    </Page>
  );
};

export default SurveyPage;
